import { theme } from '../themes';
import { Button, ThemeProvider } from '@mui/material';
import { useLocalization } from 'gatsby-theme-i18n';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { ContactForm } from '../components/ContactForm';
import { Footer } from '../components/Footer';
import { Header } from '../components/Header';
import { SlideShow } from '../components/SlideShow';
import iosBadgeRu from '../images/app-store-badge-ru.svg';
import iosBadge from '../images/app-store-badge.svg';
import androidBadgeRu from '../images/google-play-badge-ru.png';
import androidBadge from '../images/google-play-badge.png';
import cards from '../images/screenshots/cards.gif';
import info from '../images/screenshots/info.png';
import info_ru from '../images/screenshots/info_ru.png';
import iphone from '../images/screenshots/iPhone11ProMax.png';
import stats_1 from '../images/screenshots/stats_1.png';
import stats_1_ru from '../images/screenshots/stats_1_ru.png';
import stats_2 from '../images/screenshots/stats_2.png';
import stats_2_ru from '../images/screenshots/stats_2_ru.png';
import stats_3 from '../images/screenshots/stats_3.png';
import stats_3_ru from '../images/screenshots/stats_3_ru.png';
import test_1_1 from '../images/screenshots/test_1_1.png';
import test_1_1_ru from '../images/screenshots/test_1_1_ru.png';
import test_1_2 from '../images/screenshots/test_1_2.png';
import test_1_2_ru from '../images/screenshots/test_1_2_ru.png';
import test_1_3 from '../images/screenshots/test_1_3.png';
import test_1_3_ru from '../images/screenshots/test_1_3_ru.png';
import test_1_4 from '../images/screenshots/test_1_4.png';
import test_1_4_ru from '../images/screenshots/test_1_4_ru.png';
import test_1_5 from '../images/screenshots/test_1_5.png';
import test_1_5_ru from '../images/screenshots/test_1_5_ru.png';
import test_2_1 from '../images/screenshots/test_2_1.png';
import test_2_1_ru from '../images/screenshots/test_2_1_ru.png';
import test_2_2 from '../images/screenshots/test_2_2.png';
import test_2_2_ru from '../images/screenshots/test_2_2_ru.png';
import test_2_3 from '../images/screenshots/test_2_3.png';
import test_2_3_ru from '../images/screenshots/test_2_3_ru.png';
import test_2_4 from '../images/screenshots/test_2_4.png';
import test_2_4_ru from '../images/screenshots/test_2_4_ru.png';
import test_2_5 from '../images/screenshots/test_2_5.png';
import test_2_5_ru from '../images/screenshots/test_2_5_ru.png';
import test_3_1 from '../images/screenshots/test_3_1.png';
import test_3_1_ru from '../images/screenshots/test_3_1_ru.png';
import test_3_2 from '../images/screenshots/test_3_2.png';
import test_3_2_ru from '../images/screenshots/test_3_2_ru.png';
import tools from '../images/screenshots/tools.png';
import tools_ru from '../images/screenshots/tools_ru.png';
import clientUsage from '../images/screenshots/client-usage.png';
import client from '../images/screenshots/client.png';
import clients from '../images/screenshots/clients.png';
import tests from '../images/screenshots/tests.png';
import thoughtTest from '../images/screenshots/thought-test.png';
import * as css from './index.module.css';

const IndexPage = () => {
  const { t } = useTranslation();
  const { locale } = useLocalization();

  return (
    <ThemeProvider theme={theme}>
      <main className={css.main}>
        <Helmet title="CopingCard. Interactive Cognitive Behavioral Therapy Tools.">
          <meta charSet="utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <meta
            name="facebook-domain-verification"
            content="lq84cvk45v9om0fx92jgn4129xee9s"
          />
          <link
            rel="stylesheet"
            href="https://fonts.googleapis.com/icon?family=Material+Icons"
          />
        </Helmet>
        <Header />
        <div className={css.container}>
          <div id="app" className={css.appLink}></div>
          <h1 className={css.title}>{t('title1')}</h1>
          <h1 className={css.title2}>{t('title2')}</h1>
          <div className={css.intro}>{t('intro')}</div>

          {locale?.startsWith('en') ? (
            <div className={css.introVideo}>
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/_XFMXn0v8Qs"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              />
            </div>
          ) : null}
          <div className={css.intro}>{t('intro1')}</div>

          <div className={css.contentBlockLeft}>
            <div className={css.badgesContainer}>
              <div
                className={css.badge}
                onClick={() => {
                  window.open(
                    locale?.startsWith('ru')
                      ? 'https://apps.apple.com/ru/app/copingcard-cbt-mental-health/id1612621383?platform=iphone'
                      : 'https://apps.apple.com/app/copingcard-cbt-mental-health/id1612621383?platform=iphone',
                    '_blank',
                  );
                }}
              >
                <img
                  width={200}
                  src={locale?.startsWith('ru') ? iosBadgeRu : iosBadge}
                  alt="Get the app"
                />
              </div>
              <div
                className={css.badge}
                onClick={() => {
                  window.open(
                    locale?.startsWith('ru')
                      ? 'https://play.google.com/store/apps/details?id=com.copingcard&hl=ru'
                      : 'https://play.google.com/store/apps/details?id=com.copingcard',
                    '_blank',
                  );
                }}
              >
                <img
                  width={255}
                  src={locale?.startsWith('ru') ? androidBadgeRu : androidBadge}
                  alt="Get the app"
                />
              </div>
            </div>
          </div>

          <div className={css.contentBlockLeft}>
            <h3>{t('cbtTools')}</h3>
            <div className={css.contentAndImage}>
              <div className={css.content}>
                <div className={css.textBlock}>{t('cbtTools1')}</div>
                <div className={css.textBlock}>{t('cbtTools2')}</div>
                <div className={css.textBlock}>{t('cbtTools3')}</div>
              </div>
              <div>
                <SlideShow
                  imagesEn={[
                    tools,
                    test_1_1,
                    test_1_2,
                    test_1_3,
                    test_1_4,
                    test_1_5,
                    test_2_1,
                    test_2_2,
                    test_2_3,
                    test_2_4,
                    test_2_5,
                    test_3_1,
                    test_3_2,
                    cards,
                  ]}
                  imagesRu={[
                    tools_ru,
                    test_1_1_ru,
                    test_1_2_ru,
                    test_1_3_ru,
                    test_1_4_ru,
                    test_1_5_ru,
                    test_2_1_ru,
                    test_2_2_ru,
                    test_2_3_ru,
                    test_2_4_ru,
                    test_2_5_ru,
                    test_3_1_ru,
                    test_3_2_ru,
                    cards,
                  ]}
                />
              </div>
            </div>
          </div>

          <div className={css.contentBlockLeft}>
            <h3>{t('insightsAndTherapy')}</h3>

            <div className={css.contentAndImage}>
              <div>
                <SlideShow
                  imagesEn={[stats_1, stats_2, stats_3, info]}
                  imagesRu={[stats_1_ru, stats_2_ru, stats_3_ru, info_ru]}
                />
              </div>
              <div className={css.contentRight}>
                <div className={css.textBlock}>{t('insightsAndTherapy1')}</div>
                <div className={css.textBlock}>{t('insightsAndTherapy2')}</div>
              </div>
            </div>
          </div>

          <div className={css.contentBlockLeft}>
            <div className={css.badgesContainer}>
              <div
                className={css.badge}
                onClick={() => {
                  window.open(
                    locale?.startsWith('ru')
                      ? 'https://apps.apple.com/ru/app/copingcard-cbt-mental-health/id1612621383?platform=iphone'
                      : 'https://apps.apple.com/app/copingcard-cbt-mental-health/id1612621383?platform=iphone',
                    '_blank',
                  );
                }}
              >
                <img
                  width={200}
                  src={locale?.startsWith('ru') ? iosBadgeRu : iosBadge}
                  alt="Get the app"
                />
              </div>
              <div
                className={css.badge}
                onClick={() => {
                  window.open(
                    locale?.startsWith('ru')
                      ? 'https://play.google.com/store/apps/details?id=com.copingcard&hl=ru'
                      : 'https://play.google.com/store/apps/details?id=com.copingcard',
                    '_blank',
                  );
                }}
              >
                <img
                  width={255}
                  src={locale?.startsWith('ru') ? androidBadgeRu : androidBadge}
                  alt="Get the app"
                />
              </div>
            </div>
          </div>

          <div className={css.contentBlockLeft}>
            <div id="therapists" className={css.therapistsLink}></div>
            <h2>{t('providers')}</h2>

            <div className={css.contentAndImage}>
              <div className={css.content}>
                <div className={css.textBlock}>{t('providersText1')}</div>
                <div className={css.textBlock}>{t('providersText2')}</div>
                <div className={css.textBlock}>{t('providersText3')}</div>

                <div>
                  <SlideShow
                    imagesEn={[
                      clients,
                      client,
                      clientUsage,
                      tests,
                      thoughtTest,
                    ]}
                    imagesRu={[
                      clients,
                      client,
                      clientUsage,
                      tests,
                      thoughtTest,
                    ]}
                    nonMobile
                  />
                </div>
                <div className={css.textBlock}>{t('providersText4')}</div>
                <div className={css.textBlock}>{t('providersText5')}</div>
              </div>
            </div>
          </div>

          <Button
            className={css.buttonProviderAccount}
            onClick={() =>
              window?.location?.replace('https://portal.copingcard.com')
            }
            variant="contained"
            color="secondary"
          >
            {t('therapistsCallToAction')}
          </Button>
        </div>
        <br />
        {/* <div id="contact" className={css.contact}></div> */}
        <ContactForm id="form" />
      </main>
      <Footer />
    </ThemeProvider>
  );
};

export default IndexPage;
