import { Button, MenuItem, Paper, TextField } from '@mui/material';
import { useFormik } from 'formik';
import { useLocalization } from 'gatsby-theme-i18n';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { websiteFormValidator } from '../../helpers/validators';
import * as css from './contact-form.module.css';

export const ContactForm = ({ id }: { id: string }) => {
  const { t } = useTranslation();

  const { locale } = useLocalization();

  const [isMessageSent, setIsMessageSent] = React.useState(false);

  const formik = useFormik({
    initialValues: {
      email: '',
      firstName: '',
      lastName: '',
      lng: locale,
      phone: '',
      companyName: '',
      companyWebsite: '',
      role: 'Client',
      message: '',
    },
    validationSchema: websiteFormValidator,
    onSubmit: async (values) => {
      const options = {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(values),
      };
      try {
        // @ts-ignore
        fetch('https://api.copingcard.com/site-contact', options);
        setIsMessageSent(true);
      } catch (e) {
        console.log(e);
      }
    },
  });

  const emailError =
    formik.dirty && formik.touched['email'] && formik.errors['email'];

  const firstNameError =
    formik.dirty && formik.touched['firstName'] && formik.errors['firstName'];

  const lastNameError =
    formik.dirty && formik.touched['lastName'] && formik.errors['lastName'];

  const phoneError =
    formik.dirty && formik.touched['phone'] && formik.errors['phone'];

  const companyNameError =
    formik.dirty &&
    formik.touched['companyName'] &&
    formik.errors['companyName'];

  const companyWebsiteError =
    formik.dirty &&
    formik.touched['companyWebsite'] &&
    formik.errors['companyWebsite'];

  const roleError =
    formik.dirty && formik.touched['role'] && formik.errors['role'];

  const messageError =
    formik.dirty && formik.touched['message'] && formik.errors['message'];

  const roles = [
    {
      value: 'Client',
      label: t('client'),
    },
    {
      value: 'Therapist',
      label: t('therapist'),
    },
    {
      value: 'Company',
      label: t('company'),
    },
  ];

  return (
    <Paper id={id} className={css.container} elevation={5}>
      <h1 className={css.title}>{t('contactUs')}</h1>
      <div className={css.row}>
        <TextField
          className={css.field}
          error={!!emailError}
          label={emailError || t('email')}
          onChange={(v) => {
            formik.setFieldValue('email', v.target.value);
          }}
          onBlur={formik.handleBlur('email')}
          required
          value={formik.values.email}
          type="email"
          color="secondary"
        />
        <TextField
          className={css.field}
          error={!!phoneError}
          label={phoneError || t('phone')}
          onChange={(v) => formik.setFieldValue('phone', v.target.value)}
          onBlur={formik.handleBlur('phone')}
          value={formik.values.phone}
          type="tel"
          color="secondary"
        />
      </div>
      <div className={css.row}>
        <TextField
          className={css.field}
          error={!!firstNameError}
          label={firstNameError || t('firstName')}
          onChange={(v) => formik.setFieldValue('firstName', v.target.value)}
          onBlur={formik.handleBlur('firstName')}
          required
          value={formik.values.firstName}
          color="secondary"
        />
        <TextField
          className={css.field}
          error={!!lastNameError}
          label={lastNameError || t('lastName')}
          onChange={(v) => formik.setFieldValue('lastName', v.target.value)}
          onBlur={formik.handleBlur('lastName')}
          required
          value={formik.values.lastName}
          color="secondary"
        />
      </div>
      <div className={css.row}>
        <TextField
          className={css.field}
          error={!!companyNameError}
          label={companyNameError || t('companyName')}
          onChange={(v) => formik.setFieldValue('companyName', v.target.value)}
          onBlur={formik.handleBlur('companyName')}
          value={formik.values.companyName}
          color="secondary"
        />
        <TextField
          className={css.field}
          error={!!companyWebsiteError}
          label={companyWebsiteError || t('companyWebsite')}
          onChange={(v) =>
            formik.setFieldValue('companyWebsite', v.target.value)
          }
          onBlur={formik.handleBlur('companyWebsite')}
          value={formik.values.companyWebsite}
          type="url"
          color="secondary"
        />
      </div>
      <div className={css.row}>
        <TextField
          className={css.field}
          error={!!roleError}
          label={roleError || t('role')}
          onChange={(v) => {
            formik.setFieldValue('role', v.target.value);
          }}
          onBlur={formik.handleBlur('role')}
          value={formik.values.role}
          select
          color="secondary"
        >
          {roles.map((o) => (
            <MenuItem key={o.value} value={o.value}>
              {o.label}
            </MenuItem>
          ))}
        </TextField>
      </div>
      <div className={css.row}>
        <TextField
          className={css.field}
          error={!!messageError}
          label={messageError || t('message')}
          onChange={(v) => formik.setFieldValue('message', v.target.value)}
          onBlur={formik.handleBlur('message')}
          value={formik.values.message}
          color="secondary"
          multiline
          minRows={3}
          maxRows={15}
        />
      </div>
      {isMessageSent ? (
        <h3 className={css.title}>{t('messageSent')}</h3>
      ) : (
        <Button
          className={css.button}
          onClick={formik.submitForm}
          disabled={!formik.dirty || !!Object.keys(formik.errors).length}
          variant="contained"
          color="secondary"
        >
          {t('submit')}
        </Button>
      )}
    </Paper>
  );
};
