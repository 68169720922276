import * as React from 'react';
import { useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as css from './slide-show.module.css';
import iphone from '../../images/screenshots/iPhone11ProMax.png';

type Props = {
  imagesEn: string[];
  imagesRu: string[];
  nonMobile?: boolean;
};
export const SlideShow = ({
  imagesEn = [],
  imagesRu = [],
  nonMobile = false,
}: Props) => {
  const { i18n } = useTranslation();

  const images = useMemo(() => {
    return i18n.language?.startsWith('ru') ? imagesRu : imagesEn;
  }, [i18n.language]);

  const initialState = {
    idx: 0,
  };

  const reducer = (state: typeof initialState, action: { type: any }) => {
    return state.idx === images.length - 1
      ? {
          idx: 0,
        }
      : { idx: state.idx + 1 };
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const handle = setInterval(() => dispatch({ type: 'increment' }), 4000);
    return () => {
      clearInterval(handle);
    };
  }, []);

  const image = useMemo(() => {
    return (
      <div
        className={nonMobile ? css.imageContainerDesktop : css.imageContainer}
      >
        <img
          className={nonMobile ? css.desktopImage : css.image}
          src={images[state.idx]}
          alt="Screenshot"
        />
      </div>
    );
  }, [images, state.idx]);

  return images.length ? (
    <div className={css.container}>
      {nonMobile ? null : (
        <div className={css.iphoneContainer}>
          <img width={235} src={iphone} className={css.iphone} />
        </div>
      )}
      {image}
    </div>
  ) : null;
};
