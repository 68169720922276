export const colors = {
  '--white': 'white',
  '--dark-gray': '#45403e',
  '--light-gray': '#f5f1f1',
  '--border-gray': '#acbbb9',
  '--border-shadow': '#8d919133',
  '--primary-0': '#B21F00',
  '--primary-1': '#EE5C3E',
  '--primary-2': '#D9320E',
  '--primary-3': '#8F1900',
  '--primary-4': '#641100',
  '--triad-blue-0': '#055270',
  '--triad-blue-1': '#2B7895',
  '--triad-blue-2': '#0E6788',
  '--triad-blue-3': '#03425A',
  '--triad-blue-4': '#012E3F',
  '--triad-green-0': '#5CA200',
  '--triad-green-1': '#93D939',
  '--triad-green-2': '#76C60D',
  '--triad-green-3': '#4A8300',
  '--triad-green-4': '#345B00',
  '--complement-0': '#008130',
  '--complement-1': '#2DAD5C',
  '--complement-2': '#0A9D41',
  '--complement-3': '#006827',
  '--complement-4': '#00491B',
};
