import { createTheme } from '@mui/material';
import { colors } from './colors';

export const theme = createTheme({
  typography: {
    fontFamily: ['Roboto', 'sans-serif'].join(','),
  },
  palette: {
    primary: {
      light: colors['--primary-2'],
      main: colors['--primary-0'],
      dark: colors['--primary-4'],
      contrastText: colors['--white'],
    },
    secondary: {
      light: colors['--triad-blue-2'],
      main: colors['--triad-blue-0'],
      dark: colors['--triad-blue-4'],
      contrastText: colors['--white'],
    },
    success: {
      light: colors['--triad-green-2'],
      main: colors['--triad-green-0'],
      dark: colors['--triad-green-4'],
      contrastText: colors['--white'],
    },
  },
});
