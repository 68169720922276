import * as yup from 'yup';

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const websiteFormValidator = yup.object({
  email: yup.string().trim().email().required(),
  firstName: yup.string().trim().required(),
  lastName: yup.string().trim().required(),
  lng: yup.string().trim().required(),
  phone: yup.string().trim().matches(phoneRegExp),
  companyName: yup.string().trim(),
  companyWebsite: yup.string().trim(),
  role: yup.string().trim(),
  message: yup.string().trim(),
});
